import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { PopUp } from "../components/PopUp"
import { ActionPlanScreen } from "../components/ActionPlanScreen"
import { SelectedScreen } from "../components/SelectedScreen"
import { Router } from "@reach/router"

const ActionPlanPage = () => {
  return (
    <Layout>
      <SEO title="Action Plan" />
      <PopUp />
      <SelectedScreen
        disabled={false}
        animationDuration={0}
        currentScreenIndex={1}
        screensLength={1}
        actionPlan
      >
        <Router>
          <ActionPlanScreen path="/action-plan/:id" />
        </Router>
      </SelectedScreen>
    </Layout>
  )
}

export default ActionPlanPage
